import React from "react";
import * as Icon from "react-feather";
import { FormattedMessage } from "react-intl";

const navigationConfig = [
  {
    id: "overview",
    title: "app.menu.overview",
    type: "item",
    icon: <Icon.BarChart2 size={18} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "deviceList",
    title: "app.menu.deviceList",
    type: "item",
    icon: <Icon.HardDrive size={18} />,
    permissions: ["admin", "editor"],
    navLink: "/pages/devices",
  },
  {
    type: "groupHeader",
    groupTitle: (
      <FormattedMessage id="app.menu.pageTitle" defaultMessage="Pages" />
    ),
  },
  {
    id: "notification",
    title: "app.menu.notifications",
    type: "item",
    icon: <Icon.Mail size={18} />,
    permissions: ["admin", "editor"],
    navLink: "/pages/notifications",
    collapsed: true,
  },
  {
    id: "invite",
    title: "app.menu.friends",
    type: "item",
    icon: <Icon.Users size={18} />,
    permissions: ["admin", "editor"],
    navLink: "/pages/invitation",
    collapsed: true,
  },
  {
    id: "profile",
    title: "app.menu.profile",
    type: "item",
    icon: <Icon.User size={18} />,
    permissions: ["admin", "editor"],
    navLink: "/pages/profile",
    collapsed: true,
  },
  
  {
    id: 'ourSupport',
    title: "app.footer.support" ,
    icon: <Icon.LifeBuoy size={20} />,
    externalLink: true,
    newTab: true,
    navLink: 'https://h2smarthome.com/support'
  }

];

export default navigationConfig;
