import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import ReactCountryFlag from "react-country-flag";
import { Auth } from "aws-amplify";
import { FormattedMessage } from "react-intl";
import { IntlContext } from "../../utility/context/Internationalization";
import Logger from "../../helpers/Logger";

class LangugeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentLng: "en",
    };
  }

  componentDidMount() {
    if (this.props.page && this.props.page === "editProfile") {
      //   this.getCurrentLanguage();
    }
  }

  getCurrentLanguage = () => {
    Auth.currentUserInfo()
      .then((res) => {
        this.setState({ currentLng: res.attributes.locale });
      })
      .catch((err) => Logger.error("User Attributes not found."));
  };

  setUserLanguage = async (context, lng) => {
    if (!lng) {
      return null;
    }

    try {
      if (this.props.page && this.props.page === "editProfile") {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          this.setState({ isLoading: true });

          await Auth.updateUserAttributes(user, {
            locale: lng,
          });
          this.setState({ isLoading: false, currentLng: lng });
          context.state.locale = lng;
          context.switchLanguage(lng);
        }
      } else {
        this.setState({ currentLng: lng });

        context.state.locale = lng;
        context.switchLanguage(lng);
      }
    } catch (error) {
      Logger.error("error setUserLanguage info: ", error);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, currentLng } = this.state;
    let langArr = {
      en: (
        <FormattedMessage
          id="app.account.languageOptionEn"
          defaultMessage={"English"}
        />
      ),
      de: (
        <FormattedMessage
          id="app.account.languageOptionDe"
          defaultMessage={"German"}
        />
      ),
      es: (
        <FormattedMessage
          id="app.account.languageOptionEs"
          defaultMessage={"Spanish"}
        />
      ),
    };
    return (
      <IntlContext.Consumer>
        {(context) => {
          const selectedLng = context?.state?.locale;

          return (
            <UncontrolledDropdown
              tag="li"
              className="dropdown-profile-language"
              data-tour="languages"
            >
              <DropdownToggle tag="a" className="nav-link">
                {isLoading ? (
                  <Spinner color="primary" size="sm" />
                ) : (
                  <>
                    <ReactCountryFlag
                      className="country-flag"
                      countryCode={context.state.locale === "en" ? "us" : context.state.locale}
                      svg
                    />
                    <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                      {langArr[context.state.locale]}
                    </span>
                  </>
                )}
              </DropdownToggle>

              <DropdownMenu positionFixed={true}>
                <DropdownItem
                  tag="a"
                  onClick={() => this.setUserLanguage(context, "en")}
                >
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode="us"
                    svg
                  />
                  <span className="ml-1">
                    <FormattedMessage
                      id="app.account.languageOptionEn"
                      defaultMessage={"English"}
                    />
                  </span>
                </DropdownItem>

                <DropdownItem
                  tag="a"
                  onClick={() => this.setUserLanguage(context, "es")}
                >
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode="es"
                    svg
                  />
                  <span className="ml-1">
                    <FormattedMessage
                      id="app.account.languageOptionEs"
                      defaultMessage={"Spanish"}
                    />
                  </span>
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  onClick={() => this.setUserLanguage(context, "de")}
                >
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode="de"
                    svg
                  />
                  <span className="ml-1">
                    <FormattedMessage
                      id="app.account.languageOptionDe"
                      defaultMessage={"German"}
                    />
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }}
      </IntlContext.Consumer>
    );
  }
}

export default LangugeSelector;
