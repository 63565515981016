// when user sign in / out
import {
  USER_DATA,
  NOTIFICATION_DATA,
  ALARMS_DATA,
  CLEAR_ALARMS,
  GATEWAY_DATA,
  DELETE_PROFILE,
} from "../actionTypes";

function setUserData(user) {
  return {
    type: USER_DATA,
    user,
  };
}

// set notifications data
function setNotificationData(notifications) {
  return {
    type: NOTIFICATION_DATA,
    notifications,
  };
}

// set alarms data
function setAlarmsData(alarms) {
  return {
    type: ALARMS_DATA,
    alarms,
  };
}

// set alarms data
function clearAlarmsData() {
  return {
    type: CLEAR_ALARMS,
  };
}
// set gateway data
function setGatewayData(gateways) {
  return {
    type: GATEWAY_DATA,
    gateways,
  };
}

// when user sign out
function signOut() {
  return { type: DELETE_PROFILE };
}

export {
  setUserData,
  setNotificationData,
  setAlarmsData,
  clearAlarmsData,
  setGatewayData,
  signOut,
};
