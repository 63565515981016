let wssEndpoint = "wss://1vah83t32k.execute-api.eu-central-1.amazonaws.com/dev";
if (process.env.REACT_APP_STAGE === "prod") {
  wssEndpoint = "wss://71ejn3w6zj.execute-api.eu-central-1.amazonaws.com/prod";
}

const apiconfig = {
  RegistrationType: {
    type: "activationLink", // activationLink or cofirmationCode
  },
  LocalApi: {
    name: "Local Api",
    endpoint: "https://api.eesycloud.de/api",
    AccessKeyId: "9292ed56-38d7-449e-ad5b-da700d2b9518",
  },
  SSE: {
    name: "SSE Api",
    endpoint: "https://eesycloud.de/.well-known/mercure?",
  },
  WebSocket: {
    name: "Websocket",
    endpoint: wssEndpoint,
  },
  PushNotifications: {
    pushKey:
      "BHPqNX-KdgNoC6eWrGsMy7j0VbMGHMvx_dpWZi0ixXPSb9sWNk8vCZpgz7h121Bj1oa8_JbzhLHq4bBd2i1At-U",
    appSyncUrl:
      "https://2lzn4i3fsfgxdaoptsqzvbnp6y.appsync-api.eu-central-1.amazonaws.com/graphql",
    appSyncApiKey: "da2-2b6bqz75fbgr3arvkpyyvatuj4",
  },
};

export default apiconfig;
