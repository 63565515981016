import React from "react";
import ScrollToTop from "react-scroll-up";
import { Button } from "reactstrap";
import { ArrowUp  } from "react-feather";
import classnames from "classnames";
import Variables from "../../../constants/variables";
import { FormattedMessage } from "react-intl";

const Footer = (props) => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static":
          props.footerType === "static" ||
          !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden",
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          <FormattedMessage id="app.footer.copyrightText" />{" "}
          {new Date().getFullYear()}
          <a
            href={Variables.footerLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {Variables.footerName}
          </a>
          <FormattedMessage id="app.footer.copyrightReserved" />
        </span>
        <span className="float-md-right d-none d-md-block"></span>
      </p>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}

      {/* Support Button Goes Here
      
      <Button.Ripple color="info" className="buy-now">
        <LifeBuoy size={14} />
        <span className="align-middle ml-25">
          <FormattedMessage id="app.footer.support" />
        </span>
      </Button.Ripple>
      
      */}
    </footer>
  );
};

export default Footer;
