import { createStore } from 'redux';
import rootReducer from './reducers/authReducers';
//All of your other store setup stuff


const store = createStore(rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
 
export { default as AmplifyBridge } from './AmplifyBridge';
