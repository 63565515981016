// Variables

const Variables = {
    agreementUrl: "https://www.h2-smart.com/privacy-policy/", 
    footerSupportLink: "https://www.h2-smart.com/#support", 
    footerLink: "https://www.h2-smart.com/", 
    footerName: "H2 Smart Home", 
};
 

export default Variables ;