import { w3cwebsocket as W3CWebSocket } from "websocket";
import apiconfig from "../configs/config";

/**
 * Connect to the Websocket
 *
 * @param {String} topic topic name
 * @param {String} token access token for connection
 * @param {String} gwId gateway ID
 * @param {String} nodeId Node ID
 * @return {connection} Returns Websocket connection
 */
const webSocket = (topic, token, gwId, nodeId = "0") => {
  const host = apiconfig.WebSocket.endpoint;
  let nodeIdVar = "";
  let gwIdVar = "";
  if (!topic) {
    return null;
  } else if (gwId !== null) {
    nodeIdVar = `&nodeId=${nodeId}`;
    gwIdVar = `&gwId=${gwId}`;
  }

  const uri = `${host}?connectionType=${topic}${gwIdVar}${nodeIdVar}&Authorizer=${token}`;
  const client = new W3CWebSocket(uri);

  return client;
};

export default webSocket;
