import { API, Auth } from "aws-amplify";
import AWSconfig from "../aws-exports";
const AwsApi = {
  Post: async ({ apiUrl, commands }) => {
    const session = await Auth.currentSession();
    return await API.post(AWSconfig.API.apiName, apiUrl, {
      headers: {
        Authorization: session.getIdToken().getJwtToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: commands,
    });
  },
  Get: async ({ apiUrl, commands }) => {
    const session = await Auth.currentSession();
    return await API.get(AWSconfig.API.apiName, apiUrl, {
      headers: {
        Authorization: session.getIdToken().getJwtToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: { ...commands },
    });
  },
};

export default AwsApi;
