import axios from "axios";
import apiconfig from "../configs/config";

const ApiEndpoint = apiconfig.LocalApi.endpoint;
const ApiKey = apiconfig.LocalApi.AccessKeyId;

const LocalApi = {
  Post: async ({ apiUrl, commands, Token = "" }) => {
    return await axios.post(ApiEndpoint + apiUrl, commands, {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Auth-Token": ApiKey,
      },
    });
  },
  Get: async ({ apiUrl, commands, Token = "" }) => {
    return await axios.get(
      ApiEndpoint + apiUrl,

      {
        headers: {
          "X-Auth-Token": ApiKey,
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Accept": "application/json",
        },
      }
    );
  },
};

export default LocalApi;
