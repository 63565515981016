const APP_ENV =
  process.env.REACT_APP_STAGE === "prod" ? "production" : "development";

const LOG_LEVEL = APP_ENV === "production" ? "warn" : "log";

const NO_OP = (message, ...optionalParams) => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger {
  constructor(options) {
    const { level } = options || {};
    this.active = () =>  true;
    this.error = console.error.bind(console);
    if (level === "error") {
      this.warn = NO_OP;
      this.log = NO_OP; 
      return;
    }
    this.warn = console.warn.bind(console);
    if (level === "warn") {
      this.log = NO_OP; 
      return;
    }
    this.log = console.log.bind(console);
    if (APP_ENV === "production") {
      this.log = NO_OP;
      this.warn = NO_OP;
      this.error = NO_OP;
      this.active = NO_OP;
      return;
    } 
  }
}

const Logger = new ConsoleLogger({ level: LOG_LEVEL });

export default Logger;
