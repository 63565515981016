import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "dashboard",
    title: "app.menu.dashboard",
    type: "dropdown",
    icon: <Icon.Home size={16} />,
    children: [
 
 
    {
  
      id: "overview",
      title: "app.menu.overview",
      type: "item",
      icon: <Icon.BarChart2 size={12} />,
      permissions: ["admin", "editor"],
      navLink: "/"
    },
    {
      id: "deviceList",
      title: "app.menu.deviceList",
      type: "item", 
      icon: <Icon.HardDrive size={12} />,
      permissions: ["admin", "editor"],
      navLink: "/pages/devices"
    }
    ]
  },
  {
    id: "pages",
    title: "app.menu.pageTitle",
    type: "dropdown",
    icon: <Icon.File size={16} />,
    children: [
 
      {
        id: "notification",
        title: "app.menu.notifications",
        type: "item",
        icon: <Icon.Mail size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/pages/notifications",
        collapsed: true
      }, 
      {
        id: "invite",
        title: "app.menu.friends",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/pages/invitation",
        collapsed: true
      }, 
      {
        id: "profile",
        title: "app.menu.profile",
        type: "item",
        icon: <Icon.User size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/pages/profile",
        collapsed: true
      }
    ]
  } 
]

export default horizontalMenuConfig
