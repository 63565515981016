// All AWS API paths

const ApiPath = {
    getAllGateways: "/IInfrastructureManagement/getAllGateways", 
    getGateway: "/IInfrastructureManagement/getGateway", 
    flushHistory: "/IInfrastructureManagement/flushHistory", 
    donwloadHistoryFile: "/IInfrastructureManagement/getexcelfile",  
    getPermissions: "/IInfrastructureManagement/getPermissions", 
    latestMeasurement: "/IInfrastructureManagement/latestMeasurementPost", 
    registerGateway: "/IInfrastructureManagement/registerGwFrmDashboard", 
    removeGateway: "/IInfrastructureManagement/removeGwFrmDashboard",   
    registerGatewayOld: "/IInfrastructureManagement/registerGateway", 
    removeGatewayOld: "/IInfrastructureManagement/removeGateway", 
    renameGateway: "/IInfrastructureManagement/renameGateway", 
    registerNode: "/IInfrastructureManagement/registerNode",  
    renameNode: "/IInfrastructureManagement/renameNode",  
    deRegisterNode: "/IInfrastructureManagement/deRegisterNode",  
    freeNode: "/IInfrastructureManagement/freeNode", 
    removeNode: "/IInfrastructureManagement/removeNode",  
    getInvitations: "/IInfrastructureManagement/getInvitations", 
    invite: "/IInfrastructureManagement/invite", 
    acceptInvitation: "/IInfrastructureManagement/acceptInvitation", 
    revokeInvitation: "/IInfrastructureManagement/revokeInvitation", 
    deleteInvitation: "/IInfrastructureManagement/deleteInvitation",    
    mutateActuator: "/IInfrastructureManagement/mutateActuator", 
    acknowledgeAllRules: "/IRuleManagement/acknowledgeAllRules", 
    acknowledgeRule: "/IRuleManagement/acknowledgeRule", 
    listAllAcknowledgedRules: "/IRuleManagement/listAllAcknowledgedRules", 
    deleteAlarmNotification: "/IRuleManagement/deleteAlarmNotification", 
    getNonAcknowledgedAlarms: "/IRuleManagement/getNonAcknowledgedAlarms", 
    setRule: "/IRuleManagement/setRule", 
    setAlarmRule: "/IRuleManagement/setAlarmRule",
    listRules: "/IRuleManagement/listRules",
    listAlarmRules: "/IRuleManagement/listAlarmRules",
    getAlarmRules: "/IRuleManagement/getAlarmRules",
    setAlarmConfig: "/IRuleManagement/setAlarmConfig",
    resetAlarmConfig: "/IRuleManagement/resetAlarmConfig",
    getOwner: "/IInfrastructureManagement/getOwner", 
    getUserName: "/IUserManagement/getUserName", 
    getUserId: "/IUserManagement/getUserId", 
    deleteUser: "/IUserManagement/deleteUser", 
    getPlugConfig:"/IInfrastructureManagement/getPlugConfiguration",
    getAllPlugConfigurations:"/IInfrastructureManagement/getAllPlugConfigurations",
    getPlugConfiguration:"/IInfrastructureManagement/getPlugConfiguration",
    plugFlushHistory:"/IInfrastructureManagement/plugFlushHistory",
    getLinkNode:"/IInfrastructureManagement/getLinkNode",
    setLinkNode:"/IInfrastructureManagement/linkNode",
    setCO2Brightness:"/IInfrastructureManagement/setCO2Brightness",
    getCO2Brightness:"/IInfrastructureManagement/getCO2Brightness",
    setPlugConfiguration:"/IInfrastructureManagement/setPlugConfiguration",
    getPlugTime:"/IInfrastructureManagement/getPlugTime",
    setPlugTime:"/IInfrastructureManagement/setPlugTime",
    getPlugAlarmConfigurations:"/IInfrastructureManagement/getPlugAlarmConfigurations",
    setPlugAlarmConfiguration:"/IInfrastructureManagement/setPlugAlarmConfiguration",
    latestMeasurementPlugPost:"/IInfrastructureManagement/latestMeasurementPlugPost",
    createPlugGroup:"/IInfrastructureManagement/createPlugGroup",
    getAllPlugSchedulers:"/IInfrastructureManagement/getAllPlugSchedulers",
    setPlugTimeScheduler:"/IInfrastructureManagement/setPlugTimeScheduler",
    updatePlugTimeScheduler:"/IInfrastructureManagement/updatePlugTimeScheduler",
    deletePlugTimeScheduler:"/IInfrastructureManagement/deletePlugTimeScheduler",
    batchUpdatePlugTimeScheduler:"/IInfrastructureManagement/batchUpdatePlugTimeScheduler",
    getGroupId:"/IInfrastructureManagement/getGroupId",
    setGroupId:"/IInfrastructureManagement/setGroupId",
    deletePlugGroup:"/IInfrastructureManagement/deletePlugGroup",
    deleteAllAlarmNotifications:"/IRuleManagement/deleteAllAlarmNotifications"
};
 

export default ApiPath ;