import { combineReducers } from "redux";

import {
  USER_DATA,
  NOTIFICATION_DATA,
  ALARMS_DATA,
  CLEAR_ALARMS,
  GATEWAY_DATA,
  DELETE_PROFILE,
} from "../actionTypes";

const initialState = {
  gateways: [],
  alarms: [],
  notifications: [],
};
function user(state = {}, action) {
  switch (action.type) {
    case USER_DATA:
      return Object.assign({}, state, action.user);

    case DELETE_PROFILE:
      return null;
    default:
      return state;
  }
}

function data(state = initialState, action) {
  switch (action.type) {
    case GATEWAY_DATA:
      return Object.assign({}, state, { gateways: action.gateways });
    case NOTIFICATION_DATA:
      return Object.assign({}, state, { notifications: action.notifications });
    case ALARMS_DATA:
      if (action.alarms.length > 1) {
        return Object.assign({}, state, { alarms: action.alarms });
      } else if (action.alarms.length === 1) {
        return Object.assign({}, state, {
          alarms: [...action.alarms, ...state.alarms],
        });
      }

    case CLEAR_ALARMS:
      return Object.assign({}, state, { alarms: [] });

    default:
      return state;
  }
}

const Reducer = combineReducers({
  user,
  data,
});

export default Reducer;
