import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Media,
} from "reactstrap";
import { User, Power, Bell, Send } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import OnOutsiceClick from "react-outclick";

import moment from "moment";

import WebSocket from "../../../api/websocket";
import { history } from "../../../history";
import { AwsApi } from "../../../api/index";
import ApiPath from "../../../constants/api_path";
import { connect } from "react-redux";
import {
  signOut,
  setGatewayData,
  setNotificationData,
  setAlarmsData,
} from "../../../store/actions/authActions";

import LanguageSelector from "../../../components/LanguageSelector/LanguageSelector";
import { FormattedMessage } from "react-intl";
import { timestampToDate } from "../../../helpers/Common";
import Logger from '../../../helpers/Logger';

let timerId = 0;

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  const logout = () => {
    localStorage.clear();
    props.logout();
    history.push("/login");
  };

  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/pages/profile")}
        id="user_profile"
      >
        <User size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id="app.menu.profile" />{" "}
        </span>
      </DropdownItem>

      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/pages/login"
        onClick={(e) => {
          e.preventDefault();
          return logout({
            returnTo:
              window.location.origin + process.env.REACT_APP_PUBLIC_PATH,
          });
        }}
        id="logout"
      >
        <Power size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id="app.account.logoutBTN" />
        </span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    alarmsDropdown: false,
    notificationsDropdown: false,
  };

  componentDidMount() {
    this.getNonAcknowldgeRules();
    this.getInvitations();
    this.getNotifications();
  }
  componentWillUnmount() {
    this.cancelKeepAlive();
    this.getNotifications(true);
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  handleLangDropdown = () => {
    this.setState({ langDropdown: !this.state.langDropdown });
  };

  cancelKeepAlive = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
  };

  getNotifications = (close = false) => {
    const { user, data } = this.props;
    let accessToken;
    if (user) {
      const { signInUserSession } = user;
      accessToken =
        signInUserSession &&
        signInUserSession.idToken &&
        signInUserSession.idToken.jwtToken
          ? signInUserSession.idToken.jwtToken
          : null;
    }

    const topic = "notifications";

    if (!accessToken) {
      return;
    }

    const connection = WebSocket(topic, accessToken, null, null);
    if (close) {
      connection.close();
      Logger.log("Connection for Notification : is Closed");
    }

    connection.onopen = (message) => {
      // Logger.log(
      //   "WebSockets is open  now  for the Notification : ",
      //   " Message: ",
      //   message
      // );
    };

    connection.onerror = (message) => {
      Logger.log(
        "connection to WebSockets failed onerror: ",
        accessToken,
        message
      );
    };

    connection.onmessage = (message) => {
      const webSocketData = JSON.parse(message.data);

      if (webSocketData.o) {
        const type = webSocketData.o.length
          ? webSocketData.o[0]?.content?.type
          : webSocketData.o?.type;
        const title = webSocketData.o.length
          ? webSocketData.o[0]?.content?.title
          : webSocketData.o?.title;
        const body = webSocketData.o.length
          ? webSocketData.o[0]?.content?.body
          : webSocketData.o?.body;

        const timestamp = webSocketData.o.length
          ? webSocketData.o[0]?.lasttimestamp
          : null;
        const userId = webSocketData.o.length
          ? webSocketData.o[0]?.id
          : webSocketData?.userId;
        const nodeId = webSocketData.o.length
          ? webSocketData.o[0]?.nodeId
          : null;
        const currentState = webSocketData.o.length
          ? webSocketData.o[0]?.currentState
          : null;

        if (type === "INVITATION_ACCEPTED" || type === "NEW_INVITATION") {
          this.props.setNotifications([
            ...data.notifications,
            {
              type: type,
              title: title,
              body: body,
              userId: userId,
            },
          ]);
        } else if (type === "RULE_EXCEED") {
          this.props.setAlarms([
            {
              id: userId,

              currentState: currentState,
              lasttimestamp: timestamp,
              content: {
                type: type,
                title: title,
                body: body,
                nodeId: nodeId,
              },
            },
          ]);
        }
      }
    };

    timerId = setInterval(() => {
      if (connection.readyState === 1) {
        const pingMessage = {
          action: "PING",
        };
        connection.send(JSON.stringify(pingMessage));
      }
    }, 50000);
  };

  getNonAcknowldgeRules = () => {
    AwsApi.Post({
      apiUrl: ApiPath.getNonAcknowledgedAlarms,
      commands: {},
    })
      .then((res) => {
        if (res && res.o) {
          const sortedArr = res.o.sort((a, b) =>
            a.lasttimestamp < b.lasttimestamp ? 1 : -1
          );

          this.props.setAlarms(sortedArr);
        }

        //  this.setState({ alarms: res.o });
      })
      .catch((err) => Logger.error("Error from NavbarUser ", err));
  };

  getInvitations = () => {
    AwsApi.Post({
      apiUrl: ApiPath.getPermissions,
      commands: {},
    })
      .then((res) => {
        let notifArr = [];
        if (res && res.o && res.o.length > 0) {
          this.props.setGateways(res.o);
          res.o.map((item) => {
            if (item.permissionType === "i") {
              notifArr.push({
                type: "NEW_INVITATION",
                title: "New invitation received",
                body: "You have new invitation for the " + item.gatewayName,
                userId: item.user.userId,
              });

              // this.setState({
              //   notifications: [
              //     ...this.state.notifications,
              //     {
              //       type: "NEW_INVITATION",
              //       title: "New invitation received",
              //       body: "You have new invitation for the " + item.gatewayName,
              //       userId: item.user.userId,
              //     },
              //   ],
              // });
            }
          });
          this.props.setNotifications(notifArr);
        }
      })
      .catch((err) => Logger.error("Error from NavbarUser ", err));
  };

  render() {
    const { data } = this.props;
    const { notificationsDropdown, alarmsDropdown } = this.state;

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <LanguageSelector />

        <OnOutsiceClick
          onOutsideClick={() => {
            this.setState({ alarmsDropdown: false });
          }}
        >
          <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
            isOpen={alarmsDropdown}
          >
            <DropdownToggle tag="a" className="nav-link nav-link-label">
              <Bell
                size={21}
                onClick={(e) => {
                  if (data?.alarms.length === 0) {
                    handleNavigation(e, "/pages/notifications");
                  } else {
                    this.setState({ alarmsDropdown: !alarmsDropdown });
                  }
                }}
                id="bell_notification"
              />
              {data?.alarms.length > 0 && (
                <Badge pill color="primary" className="badge-up" id="badge_on_bell">
                  {`${data?.alarms.length}`}
                </Badge>
              )}
            </DropdownToggle>

            {data?.alarms.length > 0 && (
              <DropdownMenu
                onBlur={() => this.setState({ alarmsDropdown: false })}
                tag="ul"
                right
                className="dropdown-menu-media"
              >
                <li className="dropdown-menu-header">
                  <div className="dropdown-header mt-0">
                    <h3 className="text-white">{`${data?.alarms.length} New`}</h3>
                    <span className="notification-title">
                      <FormattedMessage id="app.menu.alarmsTitle" />
                    </span>
                  </div>
                </li>
                <PerfectScrollbar
                  className="media-list overflow-hidden position-relative"
                  options={{
                    wheelPropagation: false,
                  }}
                >
                  {data?.alarms.map((item, index) => (
                    <div key={index} className="d-flex justify-content-between">
                      <Media
                        className="d-flex align-items-start cursor-pointer"
                        onClick={(e) => {
                          handleNavigation(e, "/pages/notifications");
                          this.setState({ alarmsDropdown: false });
                        }}
                      >
                        <Media left href="#">
                          <Bell className="font-medium-5 primary" size={16} />
                        </Media>
                        <Media body>
                          <Media
                            heading
                            className="primary media-heading"
                            tag="h6"
                          >
                            {item.content?.title}
                          </Media>
                          <p className="notification-text">
                            {item.content?.body} : {item.currentState}
                          </p>
                        </Media>
                        <small>
                          {timestampToDate(item.lasttimestamp * 1000, (date) =>
                            moment.utc(date).fromNow()
                          )}
                        </small>
                      </Media>
                    </div>
                  ))}
                </PerfectScrollbar>
                <li className="dropdown-menu-footer">
                  <DropdownItem
                    tag="a"
                    className="p-1 text-center"
                    onClick={(e) => {
                      handleNavigation(e, "/pages/notifications");
                      this.setState({ alarmsDropdown: false });
                    }}
                  >
                    <span className="align-middle">
                      <FormattedMessage id="app.menu.seeAllAlarms" />
                    </span>
                  </DropdownItem>
                </li>
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        </OnOutsiceClick>

        <OnOutsiceClick
          onOutsideClick={() => {
            this.setState({ notificationsDropdown: false });
          }}
        >
          <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
            isOpen={notificationsDropdown}
          >
            <DropdownToggle tag="a" className="nav-link nav-link-label">
              <Send
                size={21}
                onClick={(e) => {
                  if (data?.notifications.length === 0) {
                    handleNavigation(e, "/pages/invitation");
                  } else {
                    this.setState({
                      notificationsDropdown: !notificationsDropdown,
                    });
                  }
                }}
                id="send_invitation"
              />
              {data?.notifications.length > 0 && (
                <Badge pill color="primary" className="badge-up">
                  {`${data?.notifications.length}`}
                </Badge>
              )}
            </DropdownToggle>
            {data?.notifications.length > 0 && (
              <DropdownMenu tag="ul" right className="dropdown-menu-media">
                <li className="dropdown-menu-header">
                  <div className="dropdown-header mt-0">
                    <h3 className="text-white">
                      {`${data?.notifications.length} New`}
                    </h3>
                    <span className="notification-title">
                      <FormattedMessage id="app.menu.notificationsTitle" />
                    </span>
                  </div>
                </li>
                <PerfectScrollbar
                  className="media-list overflow-hidden position-relative"
                  options={{
                    wheelPropagation: false,
                  }}
                >
                  {data?.notifications.map((item, index) => (
                    <div key={index} className="d-flex justify-content-between">
                      <Media
                        className="d-flex align-items-start cursor-pointer"
                        onClick={(e) => {
                          handleNavigation(e, "/pages/invitation");
                          this.setState({ notificationsDropdown: false });
                        }}
                      >
                        <Media left href="#">
                          <Bell className="font-medium-5 primary" size={16} />
                        </Media>
                        <Media body>
                          <Media
                            heading
                            className="primary media-heading"
                            tag="h6"
                          >
                            {item.title}
                          </Media>
                          <p className="notification-text">{item.body}</p>
                        </Media>
                        <small>
                          <time
                            className="media-meta"
                            dateTime="2015-06-11T18:29:20+08:00"
                          >
                            {/* 9 hours ago */}
                          </time>
                        </small>
                      </Media>
                    </div>
                  ))}
                </PerfectScrollbar>
                <li className="dropdown-menu-footer">
                  <DropdownItem
                    tag="a"
                    className="p-1 text-center"
                    onClick={(e) => {
                      handleNavigation(e, "/pages/invitation");
                      this.setState({ notificationsDropdown: false });
                    }}
                  >
                    <span className="align-middle">
                      <FormattedMessage id="app.menu.seeAllNotifications" />
                    </span>
                  </DropdownItem>
                </li>
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        </OnOutsiceClick>

        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              {/* <span className="user-status">Available</span> */}
            </div>
            <span data-tour="user">
              <User size={40} className="round" />

              {/*
               <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
               */}
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(signOut()),
    setNotifications: (notifications) => {
      dispatch(setNotificationData(notifications));
    },
    setAlarms: (alarms) => {
      dispatch(setAlarmsData(alarms));
    },
    setGateways: (gateways) => {
      dispatch(setGatewayData(gateways));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
