let apiConfig = {};
let userPoolWebClientid=null;
if (process.env.REACT_APP_STAGE === "prod") {
  userPoolWebClientid="5psl9i3h7i8i76l1bpldtsafue";
  apiConfig = {
    apiName: "H2APIv2Prod",
    endpoints: [
      {
        name: "H2APIv2Prod", 
        endpoint:
          "https://6n9e3r3pwk.execute-api.eu-central-1.amazonaws.com/prod",
      },
    ],
  };
} else if (process.env.REACT_APP_STAGE === "local") {
  userPoolWebClientid="202k7tg7n79njbpskvourouf6u";
  apiConfig = {
    apiName: "H2APIv2Dev",
    endpoints: [
      {
        name: "H2APIv2Dev", 
        endpoint:
          "http://0.0.0.0:8080/https://vz33y7trqg.execute-api.eu-central-1.amazonaws.com/development",
      },
    ],
  };
}
else  {
  userPoolWebClientid="202k7tg7n79njbpskvourouf6u";
  apiConfig = {
    apiName: "H2APIv2Dev",
    endpoints: [
      {
        name: "H2APIv2Dev",
        endpoint:
          "https://vz33y7trqg.execute-api.eu-central-1.amazonaws.com/development",
      },
    ],
  };
}
const awsconfig = {
  API: apiConfig,
  Auth: {
    mandatorySignIn: false,
    region: "eu-central-1",
    userPoolId: "eu-central-1_22UWVdw1y",
    // identityPoolId: "eu-central-1:03bc7fb7-2b2c-4f32-9803-8b4ebb232e25",
    userPoolWebClientId: userPoolWebClientid,
  },
  PubSub: {
    aws_pubsub_region: "eu-central-1",
    aws_pubsub_endpoint:
      "wss://a3l8bklinpu34p-ats.iot.eu-central-1.amazonaws.com/mqtt",
  },
};

export default awsconfig;
