import apiconfig from "./configs/config";
import { getUserId } from "./utility/context/Utilities";

const { PushNotifications } = apiconfig;
export async function subscribeUser() {
  let topic = "notifications";
  const userid = getUserId();
  // "38e2365e-75d2-441c-809a-086bab5e16d0"; // user id or data from local storage as per logged in user
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/sw.js").then(
      (registration) => {
        navigator.serviceWorker.ready.then(function (
          serviceWorkerRegistration
        ) {
          serviceWorkerRegistration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlB64ToUint8Array(
                PushNotifications.pushKey
              ),
            })
            .then(function (subscription) {
              // The subscription was successful
              fetch(PushNotifications.appSyncUrl, {
                method: "POST",
                headers: { "x-api-key": PushNotifications.appSyncApiKey },
                body: JSON.stringify({
                  query: `mutation($topic: String,$userid: String, $subscription: String) {subscribe(topic: $topic,userid: $userid, subscription: $subscription)}`,
                  variables: {
                    topic,
                    userid,
                    subscription: JSON.stringify(subscription),
                  },
                }),
              });
            })
            .catch(function (error) {
              console.log("Unable to subscribe to push.", error);
            });
        });
      },
      /*catch*/ (error) => {
        console.error(`Service worker registration failed: ${error}`);
      }
    );
  }
}

export async function unsubscribe() {
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.getSubscription();
  if (!subscription) return;

  const { endpoint } = subscription;
  const response = await fetch(`/subscription?endpoint=${endpoint}`, {
    method: "DELETE",
    headers: { "x-api-key": PushNotifications.appSyncApiKey },
  });

  if (response.ok) {
    await subscription.unsubscribe();
  }
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
