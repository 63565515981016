import React from "react";
import { IntlProvider } from "react-intl";

import messages_en from "../../assets/data/locales/en.json";
import messages_de from "../../assets/data/locales/de.json";
import messages_es from "../../assets/data/locales/es.json";
import { Auth } from "aws-amplify";
import Logger from "../../helpers/Logger";

const menu_messages = {
  en: messages_en,
  de: messages_de,
  es: messages_es,
};

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  state = {
    locale: "en",
    messages: menu_messages["en"],
  };
  componentDidMount() {
    this.setUserLanguage();
  }
  setUserLanguage = async () => {
    try {
      if (window.location.pathname !== "/login") {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          this.setState({
            locale: user.attributes.locale,
            messages: menu_messages[user.attributes.locale],
          });
        }
      }
    } catch (error) {
      Logger.error("error currentAuthenticatedUser info: ", error);
    }
  };
  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: (language) => {
            this.setState({
              locale: language,
              messages: menu_messages[language],
            });
          },
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
