/**
 *
 * @param {arr} arr - Array of objects for filtering
 * @param {string} attr - Attr for filter object base on it
 * @param {*} value - value as a condition
 * @returns {index}
 */
 
 export const findIndexWithAttr = (array, attr, value) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};

/**
 *
 * @param {arr} arr - Array of objects for filtering
 * @param {string} key - key name for filter object base on it
 * @param {*} value - value as a condition
 * @returns {arr}
 */

export const filterObjWithValue = (arr, key, value) => {
  let filteredArray = arr.filter((obj) => {
    return obj[key] === value;
  });

  return filteredArray;
};

/**
 * find and remove element base on key value
 * @param {arr} arr - Array of objects for filtering
 * @param {string} key - key name for filter object base on it
 * @param {*} value - value as a condition
 * @returns {arr}
 */

export const filterArrObjWithValue = (arr, key, value) => {
  let filteredArray = arr.filter((obj) => {
    return obj[key] != value;
  });

  return filteredArray;
};

/**
 * find and remove element base on key value Search base
 * @param {arr} arr - Array of objects for filtering
 * @param {string} key - key name for filter object base on it
 * @param {*} value - value as a condition
 * @returns {arr}
 */

export const filterArrObjWithValueSearch = (arr, key, value) => {
  let filteredArray = arr.filter(function (obj) {
    return obj[key].includes(value);
  });

  return filteredArray;
};

/**
 *
 * @param {arr} arr - Array of objects for filtering
 * @param {string} attr - Attr for filter object base on it
 * @param {*} value - value as a condition
 * @returns {index}
 */

export const findCurrentGatewayIndex = (array, value) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i]["target"]["gatewayId"] === value) {
      return i;
    }
  }
  return -1;
};

/**
 *
 * @param {Int} timestamp
 * @param {Fn} Callback
 * @returns {Fn} - callback date function
 */
export const timestampToDate = (timestamp, callback) => {
  const formatedDate = new Date(timestamp);
  return callback(formatedDate);
};

/**
 *
 * @param {Date} strDate
 * @returns {Int} - TimeStamp  Second
 */
export const dataToTimestamp = (strDate) => {
  const datum = new Date(strDate).getTime();
  return Math.floor(datum / 1000);
};

/**
 *
 * @param {Int} - timestamp TimeStamp
 * @return {Date} - Hour
 */
export const timestampToHour = (timestamp) => {
  const datum = new Date(timestamp);

  return datum.getHours();
};

/**
 *
 * @param {Int} - timestamp TimeStamp
 * @return {Date} - Day of week
 */
export const timestampToDay = (timestamp) => {
  const datum = new Date(timestamp);

  return datum.getDay();
};

/**
 *
 * @param {Int} - timestamp TimeStamp
 * @return {Date} - Passed Days of year
 */
export const timestampPassedDay = (timestamp) => {
  if (!timestamp) return null;
  var now = new Date().getTime();
  var diff = now - timestamp;
  const oneDay = 1000 * 60 * 60 * 24;
  const passedDays = Math.floor(diff / oneDay);
  return passedDays;
};

/**
 *
 * @param {Int} - timestamp TimeStamp
 * @return {Date} - Day of month
 */
export const timestampToMonthDay = (timestamp) => {
  const datum = new Date(timestamp);

  return datum.getDate();
};

/**
 *
 * @param {Int} startDay -  number of Days ago
 * @returns {Int} - TimeStamp  Second
 */
export const daysAgoToTimestamp = (startDay = 0) => {
  const ourCurrentDate = new Date();
  const pastDate = ourCurrentDate.getDate() - startDay;
  // In Millisecond
  ourCurrentDate.setDate(pastDate);
  ourCurrentDate.setHours(0);
  ourCurrentDate.setMinutes(0);
  const datum = ourCurrentDate.setSeconds(0);

  // convert to Second
  return Math.floor(datum / 1000);
};

/**
 *
 * @param {Int} startMin -  Minutes ago
 * @returns {Int} - TimeStamp  Second
 */
export const minsAgoToTimestamp = (startMin = 10) => {
  const ourCurrentDate = new Date();
  const pastDate = ourCurrentDate.getMinutes() - startMin;

  // In Millisecond
  const datum = ourCurrentDate.setMinutes(pastDate);

  // convert to Second
  return Math.floor(datum / 1000);
};

/**
 * Convert Date to Year,Month,Day Numbers
 *
 * @param {Date} date input Date
 * @param {Date} time input Time
 * @return {Obj} Returns date as numbers
 */
export const convertDateToNumber = (date, time) => {
  const year = parseFloat(date.getFullYear() - 1900);
  const month = date.getMonth();
  const day = date.getDate();

  const hour = time.getHours();
  const minute = time.getMinutes();
  return {
    year,
    month,
    day,
    hour,
    minute,
  };
};

/**
 * Convert Year,Month,Day Numbers to Date
 *
 * @param {Int} year - year number
 * @param {Int} month - month number
 * @param {Int} day - day number
 * @param {Int} hour - hour number
 * @param {Int} minute - minute number
 * @return {Date} - Returns date
 */
export const convertNumbersToDate = (
  year,
  month = 1,
  day = 1,
  hour = 1,
  minute = 0
) => {
  //const Years = parseFloat(year + 1900);
  const Years = year;
  const Month = month <= 12 ? month - 1 : 2;
  const Day = day;

  const date = new Date(Years, Month, Day, hour, minute);

  return date;
};

/**
 * Convert second to minutes
 * @param {Int} second
 * @returns {Int} - time as minutes
 */
export const secondsToMinute = (seconds) => {
  const minutes = seconds / 60;
  return Math.floor(minutes);
};

/**
 * Convert minutes to second
 * @param {Int} minutes
 * @returns {Int} - time as Second
 */
export const minutesToSecond = (minutes) => {
  const seconds = minutes * 60;
  return Math.floor(seconds);
};


/**
 * Add or substract Days from date 
 * @param {Date} date
 * @param {String} opration - "+" to add and "-" to substract
 * @param {Int} number - number of days 
 * @returns {Date}  
 */

export const addMinusDays = (date, opration, number) => {
 if (!date) return;
 if (opration === "+") {
   return new Date(date).setDate(new Date(date).getDate() + number);
 } else if (opration === "-") {
   return new Date(date).setDate(new Date(date).getDate() - number);
 }
};

/**
 * Converts a day number to a string.
 *
 * @param {Number} dayIndex
 * @return {String} Returns day as string
 */
export const dayOfWeekAsString = (dayIndex) => {
  return (
    [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][dayIndex] || ""
  );
};
/**
 * Find max number in nested Array
 *
 * @param {array} first array
 * @param {array} second array
 * @return {number} Returns maximum number in array
 */
export const findMaxNumber = (first, second) => {
  const firstMax = Math.max(...[].concat(...first));
  const secondMax = Math.max(...[].concat(...second));
  return Math.max(firstMax, secondMax);
};

/**
 * split the node ID to get the id and type
 * @param {String} nodeID - Node ID
 * @returns {Obj} - node data
 */

export const splitNodeData = (nodeID) => {
  const nodeIdExport = {
    NodeIDVal: null,
    NodeTypeVal: null,
    NodeVer: null,
    NodeShei: null,
    NodeFullVal: null,
  };

  if (nodeID.length >= 20) {
    nodeIdExport.NodeIDVal = nodeID.substring(0, 15);
    nodeIdExport.NodeTypeVal = parseInt(nodeID.substring(15, 18));
    nodeIdExport.NodeVer = nodeID.substring(18, 19);
    nodeIdExport.NodeShei = nodeID.substring(19, 20);
    nodeIdExport.NodeFullVal = null;
    return nodeIdExport;
  } else if (nodeID.indexOf("-") > -1) {
    const splitNodeType = nodeID.split("-");
    nodeIdExport.NodeIDVal = splitNodeType[0];
    nodeIdExport.NodeTypeVal = parseInt(splitNodeType[1]);
    nodeIdExport.NodeVer = "0";
    nodeIdExport.NodeShei = "0";
    nodeIdExport.NodeFullVal = nodeID;
    return nodeIdExport;
  } else {
    return nodeIdExport;
  }
};

/**
 * split the node ID to get just id
 * @param {String} nodeID - Node ID
 * @returns {String} - node Id
 */

export const splitNodeId = (nodeID) => {
  if (!nodeID) return false;

  let fullNodeId = nodeID;
  if (fullNodeId.indexOf("-") > -1) {
    const splitNodeType = fullNodeId.split("-");
    fullNodeId = splitNodeType[0];
  }

  return fullNodeId;
};

/**
 * formatGatewayName add owner name in front of gateways with just read access.
 * @param {Obj} gatewayObj - List of Gateways
 * @returns {String} - Formatted Gateway name
 */

export const formatGatewayName = (gatewayObj) => {
  let gatewayName = "";
  if (!gatewayObj) {
    return null;
  }

  if (gatewayObj.permissionType === "a") {
    const ownerName = ` (${gatewayObj.owner.userName})`;
    gatewayName = gatewayObj.gatewayName + ownerName;
  } else if (gatewayObj.permissionType === "o") {
    gatewayName = gatewayObj.gatewayName;
  }

  return gatewayName;
};

/**
 *
 * @param {Int} decimal - Decimal Value
 * @returns {Int} - Converted Decimal (Negative/Positive) Value
 */

export const uintToInt = (decimal, nbit) => {
  nbit = +nbit || 32;
  if (nbit > 32) console.log("only supports ints up to 32 bits");
  decimal <<= 32 - nbit;
  decimal >>= 32 - nbit;
  return (decimal / 100).toFixed(0);
};
